import { createSlice } from "@reduxjs/toolkit";
import { getVehicleTypeList } from "../Actions/getVehicleTypeAction";

export const vehicleTypeReducer = createSlice({
    name: "vehicleTypeReducer",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    reducers: {
        resetVehicleTypeList: (state, action) => {
            state.data = undefined
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getVehicleTypeList.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getVehicleTypeList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(getVehicleTypeList.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const { resetVehicleTypeList } = vehicleTypeReducer.actions;