import { createSlice } from "@reduxjs/toolkit";
import { getWalletBalance } from "../Actions/fetchWalletBalance";

export const getWalletBalanceReducer = createSlice({
    name: "getWalletBalanceReducer",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    reducers: {
        resetWalletData: (state, action) => {
            state.data = undefined
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getWalletBalance.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getWalletBalance.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(getWalletBalance.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const { resetWalletData } = getWalletBalanceReducer.actions;