import { createSlice } from "@reduxjs/toolkit";

export const loginReducer = createSlice({
    name: "login",
    initialState: {
        data: {
            "isLoggedIn": false
        }
    },
    reducers: {
        isLoggedInAction: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { isLoggedInAction } = loginReducer.actions;