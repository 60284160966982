import React, { useState, useEffect, useRef } from "react";
import "./DropDown.css";
import arrow from "../../../Assets/Images/arrow.svg";

const DropDown = (props) => {
  const {
    dropDownMenu,
    className,
    classNameForHead,
    Head,
    clickEvent,
    revealOptions,
    afterTrueClass,
    valueSelectionEvent,
    revealOptionsClasses,
    classNameTwo,
    dropDownHeadClass,
    dropdownId,
    idForHead,
    idForHeadTxt,
    placeHolder,
    headClick,
    name,
    inputId,
    value,
    label,
    arrowInGray,
    idForArrow,
    idForParentObj,
    inputClass,
    errState,
    errMsg,
    labelclass,
    errClassName,
  } = props;

  return (
    <>
      <div
        className={
          revealOptions
            ? afterTrueClass
            : errState
              ? errClassName + " " + className
              : className
        }
      >
        {
          (value !== null && value !== undefined) &&
          (<p className="dropdown-header">{Head}</p>)
        }
        <div
          id={idForHead}
          className={dropDownHeadClass}
          onClick={(e) => headClick(e)}
        >
          {value === "" || value === null || value === undefined ? (
            <p className={classNameForHead} id={idForHeadTxt}>
              {Head}
            </p>
          ) : (
            <input
              type="text"
              className={inputClass ? inputClass : "Input_Dropdown"}
              value={value}
              name={name}
              autoComplete="off"
              placeholder={placeHolder}
              id={inputId}
              readOnly
            />
          )}

          {arrowInGray ? (
            <img id="arrowInGray" src={arrowInGray} alt="" srcSet="" />
          ) : (
            <img id={idForArrow} src={arrow} alt="" srcSet="" />
          )}
        </div>
        <div
          id={dropdownId}
          className={revealOptions ? revealOptionsClasses : classNameTwo}
          onClick={(e) => clickEvent(e)}
        >
          {dropDownMenu?.map((item, index) => (
            <>
              <button
                id={idForParentObj}
                key={index}
                onClick={valueSelectionEvent}
                className="dropdownItems-text"
                value={item.value}
                name={name}
                type="button"
              >
                {item.label}
              </button>
            </>
          ))}
        </div>
        {errState && (
          <p className="err-msg err-msg-absolute">{errState ? errMsg : ""}</p>
        )}
      </div>
    </>
  );
};

export default DropDown;
