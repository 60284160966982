import { createAsyncThunk } from "@reduxjs/toolkit";

export const getWalletBalance = createAsyncThunk(
    "walletBalance",
    async (userToken) => {
        const response = await fetch(
            "https://gkaxpf3fw4.execute-api.ap-south-1.amazonaws.com/prod/prod_brilljust_get_walletBalance",
            {
                method: "POST",
                headers: new Headers({
                    "Authorization": `${userToken}`,
                    'Content-Type': 'application/json'
                }),
                // headers: {
                //     "Content-type": "application/json; charset=UTF-8",
                // },
            }
        );
        return response.json();
    }
);