import React from "react";
import "./PolicyCard.css";
import NoPoliciesFound from "./NoPolicyFound";

const PolicyCard = (props) => {
  const {
    FormValue,
    handleInputChange,
    NoPolicyFound,
    IsUploadPolicyAttempted,
    handleUploadPolicy,
    ShowPolicyTypeList,
    setShowPolicyTypeList,
    handleUploadedPolicyInsurer,
    handleCancelPolicyUpload,
  } = props;

  return (
    <>
      <NoPoliciesFound
        IsUploadPolicyAttempted={IsUploadPolicyAttempted}
        handleUploadPolicy={handleUploadPolicy}
        FormValue={FormValue}
        handleInputChange={handleInputChange}
        ShowPolicyTypeList={ShowPolicyTypeList}
        setShowPolicyTypeList={setShowPolicyTypeList}
        handleUploadedPolicyInsurer={handleUploadedPolicyInsurer}
        handleCancelPolicyUpload={handleCancelPolicyUpload}
      />
    </>
  );
};

export default PolicyCard;
