import React, { useEffect } from "react";
import "./ProductSelection.css";
import { useSelector, useDispatch } from "react-redux";
import { getProductList } from "../../../../Redux/Actions/getProductList";
import { resetClientDetails } from "../../../../Redux/Reducers/ClientDetailsReducer";

const ProductSelection = (props) => {
  const { event } = props;

  const dispatch = useDispatch();

  const getUserToken = useSelector((state) => {
    return state?.getLoggedInStatus?.data?.loginData?.token;
  });

  const storeProducList = useSelector((state) => {
    return state?.getProductList?.data?.data;
  });

  useEffect(() => {
    dispatch(getProductList(getUserToken));
    dispatch(resetClientDetails());
  }, []);

  return (
    <>
      <section className="productselection-container">
        <div className="productselection-parent maxWidth">
          <div className="productContainer">
            {storeProducList?.map((data, key) => (
              <div
                id={data.id}
                key={key}
                className="prod-block"
                onClick={(e) =>
                  event(data, "productName")
                }
              >
                <div className="icon-container-prod">
                  <img
                    src={data.product_img}
                    className="prod-block-img"
                    alt=""
                    srcSet=""
                  />
                </div>
                <p className="prod-name">{data.name}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductSelection;
