import React, { useState } from "react";
import InputFields from "../../../InputField/TextField/InputField";
import Button from "../../../InputField/Button/Button";
import DropDown from "../../../InputField/Dropdown/DropDown";
import grayArrow from "../../../../Assets/Images/grayArrow.svg";
import Calender from "../../../InputField/Calender/Calender";

const EditPersonalDetails = (props) => {
  const {
    HanldeFormData,
    FormData,
    setComponentToRender,
    handleBackBtn,
    CallForEdit,
    errors,
  } = props;

  const [ShowNomineeRelation, setShowNomineeRelation] = useState(false);
  const [ShowgenderRelation, setShowgenderRelation] = useState(false);

  const handlePopUps = () => {
    if (ShowNomineeRelation) {
      setShowNomineeRelation(!ShowNomineeRelation);
    }
  };
  const handlegenderPopUps = () => {
    if (ShowgenderRelation) {
      setShowgenderRelation(!ShowgenderRelation);
    }
  };

  return (
    <section className="editVehicleDetails-container" onClick={handlePopUps}>
      <div className="editVehicleDetails-parent maxWidth">
        <div className="form-container-editVehicleDetails">
          <div className="head-form-container">
            <p>Personal Details</p>
            <p className="imp-msg">All fields are mandatory</p>
          </div>

          <div className="input-list-editVehicleDetails">

            <div className="input-container-form">
              <InputFields
                type="text"
                placeholder="First Name"
                name="firstName"
                className=""
                onChangeEvent={HanldeFormData}
                value={FormData.firstName}
                errState={errors?.firstName ? true : false}
                errMsg={errors?.firstName}
                errClassName="input-err"
              />
              <InputFields
                type="text"
                placeholder="Last Name"
                name="lastName"
                className=""
                onChangeEvent={HanldeFormData}
                value={FormData.lastName}
                errState={errors?.lastName ? true : false}
                errMsg={errors?.lastName}
                errClassName="input-err"
              />
            </div>


            <div className="input-container-form">
              <Calender
                className="pForm_datePicker"
                placeholder="Date of Birth"
                name="dob"
                id="dob"
                value={FormData.dob}
                changeEvent={(e) => HanldeFormData(e)}
                errMsg={errors?.dob}
              />
              <DropDown
                dropDownMenu={genderList}
                value={FormData?.gender}
                name="gender"
                classNameForHead="headTxtClassPform"
                Head="Select Gender"
                arrowInGray={grayArrow}
                inputId="gender"
                clickEvent={(e) => handlegenderPopUps()}
                handleChangeInput={(e) => console.log(e)}
                headClick={(e) => setShowgenderRelation(!ShowgenderRelation)}
                revealOptions={ShowgenderRelation}
                afterTrueClass="dropDown_container NoBorderRadius"
                className="dropDown_container"
                valueSelectionEvent={HanldeFormData}
                revealOptionsClasses=" dropDownOptions showdropDownOptions "
                classNameTwo="dropDownOptions"
                dropDownHeadClass="dropDownHead"
                errState={errors?.gender ? true : false}
                errMsg={errors?.gender}
                errClassName="input-err"
              />
            </div>

            <div className="input-container-form">
              <InputFields
                type="number"
                placeholder="Mobile No."
                name="mobileNumber"
                className=""
                onChangeEvent={HanldeFormData}
                value={FormData.mobileNumber}
                errState={errors?.mobileNumber ? true : false}
                errMsg={errors?.mobileNumber}
                errClassName="input-err"
              />
              <InputFields
                type="email"
                placeholder="Email ID"
                name="emailId"
                className=""
                onChangeEvent={HanldeFormData}
                value={FormData.emailId}
                errState={errors?.emailId ? true : false}
                errMsg={errors?.emailId}
                errClassName="input-err"
              />
            </div>

            <div className="input-container-form">
              <InputFields
                type="text"
                placeholder="Nominee Name"
                name="nomineeName"
                className=""
                onChangeEvent={HanldeFormData}
                value={FormData.nomineeName}
                errState={errors?.nomineeName ? true : false}
                errMsg={errors?.nomineeName}
                errClassName="input-err"
              />

              <DropDown
                dropDownMenu={nomineeRelationList}
                value={FormData?.nomineeRelation}
                name="nomineeRelation"
                classNameForHead="headTxtClassPform"
                Head="Select Nominee Relation"
                arrowInGray={grayArrow}
                inputId="nomineeRelation"
                clickEvent={(e) => console.log(e)}
                handleChangeInput={(e) => console.log(e)}
                headClick={(e) => setShowNomineeRelation(!ShowNomineeRelation)}
                revealOptions={ShowNomineeRelation}
                afterTrueClass="dropDown_container NoBorderRadius"
                className="dropDown_container"
                valueSelectionEvent={HanldeFormData}
                revealOptionsClasses=" dropDownOptions showdropDownOptions "
                classNameTwo="dropDownOptions"
                dropDownHeadClass="dropDownHead"
                errState={errors?.nomineeRelation ? true : false}
                errMsg={errors?.nomineeRelation}
                errClassName="input-err"
              />
            </div>

            <div className="input-container-form">
              <InputFields
                type="number"
                placeholder="Enter nominee age"
                name="nomineeAge"
                className="vehicleNumber"
                onChangeEvent={HanldeFormData}
                value={FormData.nomineeAge}
                errState={errors?.nomineeAge ? true : false}
                errMsg={errors?.nomineeAge}
                errClassName="input-err"
              />

              <div></div>
            </div>

            <div className="btngrp-container">
              <Button
                text="Back"
                className="secondry_btn btn-vehicledetails "
                event={(e) =>
                  handleBackBtn(
                    CallForEdit ? "dropToProductPage" : "editPersonalDetails"
                  )
                }
              />
              <Button
                text="Save"
                className="button btn-vehicledetails "
                event={(e) => setComponentToRender("editPersonalDetails")}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const nomineeRelationList = [
  { label: "Husband", value: "Husband" },
  { label: "Wife", value: "Wife" },
  { label: "Spouse", value: "Spouse" },
  { label: "Father", value: "Father" },
  { label: "Mother", value: "Mother" },
  { label: "Son", value: "Son" },
  { label: "Daughter", value: "Daughter" },
  { label: "Brother", value: "Brother" },
  { label: "Sister", value: "Sister" },
  { label: "Other", value: "Other" },
];

const genderList = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Others", value: "Others" }
];

export default EditPersonalDetails;
