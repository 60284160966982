import { createSlice } from "@reduxjs/toolkit";
import { getStateAction, getCityAction } from "../Actions/getStateCityAction";

export const getStateReducer = createSlice({
    name: "getStateReducer",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(getStateAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getStateAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(getStateAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const getCityReducer = createSlice({
    name: "getCityReducer",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(getCityAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getCityAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(getCityAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
});