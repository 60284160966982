import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchMmvAction = createAsyncThunk(
    "fetchVehicleDetails",
    async (regNo) => {
        const response = await fetch(
            `https://h2v0zqu6h5.execute-api.ap-south-1.amazonaws.com/prod/prod_bmi_getVehicleDetails?regNo=${regNo}&vehicleType=2W`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }
        );
        return response.json();
    }
);
