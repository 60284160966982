import { createAsyncThunk } from "@reduxjs/toolkit";

// get State List
export const getStateAction = createAsyncThunk("getStateList",
    async (type) => {
        const response = await fetch(
            `https://h2v0zqu6h5.execute-api.ap-south-1.amazonaws.com/prod/prod_bmi_get_rto?type=statename`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }
        );
        return response.json();
    });

// get City List
export const getCityAction = createAsyncThunk(
    "getCityList",
    async (state) => {
        const response = await fetch(
            `https://h2v0zqu6h5.execute-api.ap-south-1.amazonaws.com/prod/prod_bmi_get_rto?type=city&statename=${state}`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }
        );
        return response.json();
    }
);