import {
    getBikeBrandAction,
    getBikeModelAction,
    getVariantAction,
} from "../Actions/MmvListAction";
import { createSlice } from "@reduxjs/toolkit";

export const getBrandReducer = createSlice({
    name: "getBrandReducer",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(getBikeBrandAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getBikeBrandAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(getBikeBrandAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const getModelReducer = createSlice({
    name: "getModelReducer",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(getBikeModelAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getBikeModelAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(getBikeModelAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        resetModelAction: (state, action) => {
            state.data = undefined
        }
    }
});

export const { resetModelAction } = getModelReducer.actions;

export const getVariantReducer = createSlice({
    name: "getVariantReducer",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(getVariantAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getVariantAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(getVariantAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
    reducers: {
        resetVariantAction: (state, action) => {
            state.data = undefined
        }
    }
});

export const { resetVariantAction } = getVariantReducer.actions;