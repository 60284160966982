import React from "react";
import back from "../../../Assets/Images/back.svg";
import "./Breadcrumbs.css";

const Breadcrumbs = (props) => {
  const { event, componentName } = props;

  return (
    <>
      <div className="back-breadcrumb" onClick={(e) => event(componentName)}>
        <img src={back} alt="" srcSet="" />
        <p>Back</p>
      </div>
    </>
  );
};

export default Breadcrumbs;
