import { createAsyncThunk } from "@reduxjs/toolkit";

export const getProductList = createAsyncThunk(
    "getProductList",
    async (token) => {
        const response = await fetch(
            `https://gkaxpf3fw4.execute-api.ap-south-1.amazonaws.com/prod/prod_brilljust_get_productList`,
            {
                method: "POST",
                headers: new Headers({
                    "Authorization": `${token}`,
                    'Content-Type': 'application/json'
                }),
            }
        );
        return response.json();
    }
);