import { createSlice } from "@reduxjs/toolkit";
import { fetchClientDetails,fectchEngineValidaionAction } from "../Actions/fetchClientDetails";

export const clientDetailsReducer = createSlice({
    name: "clientDetailsReducer",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    reducers: {
        resetClientDetails: (state, action) => {
            state.data = undefined
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchClientDetails.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchClientDetails.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchClientDetails.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const { resetClientDetails } = clientDetailsReducer.actions;
export const engineValidationReducer = createSlice({
    name: "clientDetailsReducer",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    reducers: {
        resetEngineValidationDetails: (state, action) => {
            state.data = undefined
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fectchEngineValidaionAction.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fectchEngineValidaionAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(fectchEngineValidaionAction.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const { resetEngineValidationDetails } = engineValidationReducer.actions;