import React from "react";
import "./RadioButton.css";

const RadioButton = (props) => {
  const { label, className, id, groupName, value, changeEvent, isChecked } =
    props;

  return (
    <div>
      <div className="radio-group">
        <input
          className="radio-input"
          name={groupName}
          id={id}
          value={value}
          type="radio"
          onChange={changeEvent}
          checked={isChecked}
        />
        <label className="radio-label" htmlFor={id}>
          <span className="radio-inner-circle"></span>
          {label}
        </label>
      </div>
    </div>
  );
};

export default RadioButton;
