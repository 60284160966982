import { createSlice } from "@reduxjs/toolkit";
import { fetchTransactionList } from "../Actions/fetchTransactionList";

export const transactionListReducer = createSlice({
    name: "transactionListReducer",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    reducers: {
        resetTransactionList: (state, action) => {
            state.data = undefined
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTransactionList.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchTransactionList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchTransactionList.rejected, (state, action) => {
            console.error("Error", action.payload);
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const { resetTransactionList } = transactionListReducer.actions;