import jsPDF from 'jspdf'
import ReactDOMServer from 'react-dom/server'
import logo from "../Assets/Images/brillJustjpg.png"
import call from "../Assets/Images/phone.png"
import tow from "../Assets/Images/towing.png"
import mail from "../Assets/Images/supportMail.png";
import allianceLogo from "../Assets/Images/AllianceLogo.png";
import onlineSupport from "../Assets/Images/onlineSupport.png";
import towAssist from "../Assets/Images/towAssist.png";
import rundown from "../Assets/Images/rundown.png";
import tyre from "../Assets/Images/tyre.png";
import fuel from "../Assets/Images/fuelAssist.png";
import customer from "../Assets/Images/customer.png";
import urgent from "../Assets/Images/urgent.png";
import emergency from "../Assets/Images/emergencyAssit.png";
import keyAssist from "../Assets/Images/keyAssit.png";
import taxiAssist from "../Assets/Images/taxiAssit.png";
import accomadation from "../Assets/Images/accomadationAssit.png";
import outward from "../Assets/Images/outWard.png";
import arrangement from "../Assets/Images/arrangement.png";
import coverage from "../Assets/Images/coverageAssit.png";
import future from "../Assets/Images/futureLogo.png";
import '../Components/PDF-Container/Pdf.css'
import PoppinsRegular from "../Assets/Font/Merriweather,Poppins/Poppins/Poppins-Regular.ttf";
import moment from 'moment';
import highligtedCircle from "../Assets/Images/initiate claim.png"
import tick from "../Assets/Images/tick.png"


export const exportPDF = async (formData, policyNumber, is_merge_pdf = false) => {
    return new Promise(
        async (resolve, reject) => {
            try {
                let startDate = moment(formData.policyStartDate);
                let dob = moment(formData.dob).format("D-MM-YYYY");
                let endDate = moment(moment(formData.policyStartDate).add(1, 'y')).subtract(1, 'day');

                let fromYear = moment(startDate).format('YYYY');
                let fromMonth = moment(startDate).format('MMMM');
                let fromDate = moment(startDate).format('D');

                let toYear = moment(endDate).format('YYYY');
                let toMonth = moment(endDate).format('MMMM');
                let toDate = moment(endDate).format('D');

                let basicPlanAmt = (parseInt(formData.planBuyPrice)) / (1 + 0.18);
                let basicCcAmt = formData.cc_plan_amount ? (parseInt((parseInt(formData.cc_plan_amount)) / (1 + 0.18))) : 0;
                let planGST = (parseInt(formData.planBuyPrice) +
                    (formData.cc_plan_amount ? parseInt(formData?.cc_plan_amount) : 0))
                    - basicPlanAmt
                    - basicCcAmt;
                basicPlanAmt = basicPlanAmt.toFixed(2);
                planGST = planGST.toFixed(2);

                console.log(formData?.is_hide_master_policy, "sdkjfhfdskjh");

                let element = (
                    <>
                        {/* <!-- PDF PAGE ONE --> */}

                        <section className="pdf-container">
                            <div className="header-container-pdf">
                                <img src={logo} width={230} height={50} alt="" srcSet="" />
                                <div className="alliance_section">
                                    <p className="alliance_heading">
                                        Brilljust Solutions Private Limited
                                    </p>
                                    <p>
                                        ALT-F, Suncity Success Tower, <br /> Sec-65 Golf Course Ext.{" "}
                                        <br /> Road,Gurgaon (hr) :122005
                                    </p>
                                </div>
                            </div>

                            <div className="pdfContent">
                                <div className="pdfContent-header">
                                    <div>
                                        <p className="headTitle">
                                            Product: <span>{formData.productName}</span>
                                        </p>
                                        <p className="headTitle-gray">Master Policy Number: {formData.masterPolicyNumber}</p>
                                    </div>

                                    <div className="policyHolderDetailsBlock policyHolderDetailsBlock_blue">
                                        <div className="policyHolderDetail-container">
                                            <div className="titleDescription-policy titleDescription-policy_copy">
                                                <p>Certificate Number</p>
                                                <p>{policyNumber}</p>
                                            </div>
                                            <div className="titleDescription-policy"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex-container-pdf">
                                    <div className="flex-container-pdf-left">
                                        <div className="policyHolderDetails">
                                            <div className="policyHolderDetailsBlock">
                                                <div className="policyHolderDetail-container">
                                                    <div className="titleDescription-policy">
                                                        <p>Insured Name</p>
                                                        <p>{formData.firstName + " " + formData.lastName}</p>
                                                    </div>
                                                    <div className="titleDescription-policy">
                                                        <p>Mobile Number</p>
                                                        <p>{formData.mobileNumber}</p>
                                                    </div>
                                                </div>
                                                <div className="policyHolderDetail-container">
                                                    <div className="titleDescription-policy">
                                                        <p>Email ID</p>
                                                        <p>{formData.emailId}</p>
                                                    </div>
                                                    <div className="titleDescription-policy"></div>
                                                </div>
                                                <div className="policyHolderDetail-container">
                                                    <div className="titleDescription-policy">
                                                        <p>Address</p>
                                                        <p>
                                                            {formData.addressLineOne + " " + formData.addressLineTwo}
                                                        </p>
                                                    </div>
                                                    <div className="titleDescription-policy"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="vehicle-details-block">
                                            <div className="vehicle-details-header">
                                                <img src={tow} alt="" />
                                                <p>Vehicle Details</p>
                                            </div>
                                            <div className="title-desc-vehicle">
                                                <p>Registration Number</p>
                                                <p>{formData.businessType?.toLowerCase() == "new" ? "NEW" : formData.vehicleNumber}</p>
                                            </div>
                                            <div className="title-desc-vehicle">
                                                <p>Vehicle Type</p>
                                                <p>{formData?.vehicleType ? formData?.vehicleType : "Two Wheeler"}</p>
                                            </div>
                                            <div className="title-desc-vehicle">
                                                <p>Make</p>
                                                <p>{formData.make}</p>
                                            </div>
                                            <div className="title-desc-vehicle">
                                                <p>Model / Variant</p>
                                                <p>{formData.model}</p>
                                            </div>
                                            <div className="title-desc-vehicle">
                                                <p>Engine Number</p>
                                                <p>{formData.engineNumber}</p>
                                            </div>
                                            <div className="title-desc-vehicle">
                                                <p>Chassis Number</p>
                                                <p>{formData.chassisNumber}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex-container-pdf-right">
                                        <div className="policyDetails">
                                            <div className="calendar_container_pdf">
                                                <div className="calendar">
                                                    <div className="calendar-year">
                                                        <p>{fromYear}</p>
                                                    </div>
                                                    <div className="calendar-details">
                                                        <p>Certificate Start Date</p>
                                                        <p>{fromDate}</p>
                                                        <p>{fromMonth}</p>
                                                    </div>
                                                </div>
                                                <div className="calendar">
                                                    <div className="calendar-year">
                                                        <p>{toYear}</p>
                                                    </div>
                                                    <div className="calendar-details">
                                                        <p>Certificate End Date</p>
                                                        <p>{toDate}</p>
                                                        <p>{toMonth}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="comp-details-container">
                                            <div className="comp-details">
                                                <div className="comp-details-block">
                                                    <div className="alliance_head_details">
                                                        <p>Alliance Assure Private Limited</p>
                                                        <img src={allianceLogo} alt="" />
                                                    </div>

                                                    <p style={{ color: "#1A1B1D" }}>For Any Assistance For RSA</p>

                                                    <p>Call or Email Us:</p>

                                                    <div className="support_container_pdf">
                                                        <img src={call} alt="" srcset="" />
                                                        <p>1800 212 7559</p>
                                                    </div>

                                                    <div className="support_container_pdf">
                                                        <img src={mail} alt="" srcset="" />
                                                        <p>support@allianceassure.com</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="policy-container-pdf">
                                                <p>{formData.is_opt_gst ? "Plan Details" : "Payment Detail"}</p>
                                                <div className="payment-head-value">
                                                    <p>{formData.is_opt_gst ? `PLAN-${formData.planBuyPrice}` : "RSA BASE"} </p>
                                                    {!formData.is_opt_gst &&
                                                        <p>₹ {basicPlanAmt}</p>
                                                    }
                                                </div>
                                                {formData?.cc_plan_amount ?
                                                    <>
                                                        <div className="payment-head-value">
                                                            <p>{formData.is_opt_gst ? (`Complete Care Plan `) : "Complete Care Plan"}
                                                                {formData.is_opt_gst ? <img src={tick} alt="" srcset="" /> : ''}
                                                            </p>

                                                            {!formData.is_opt_gst &&
                                                                <p>₹ {basicCcAmt}</p>
                                                            }
                                                        </div>
                                                    </>
                                                    : ''
                                                }
                                                {!formData.is_opt_gst ?
                                                    <>
                                                        <div className="payment-head-value">
                                                            <p>{formData.state === "HARYANA" ? "CGST (9%) + SGST (9%)" : "IGST (18%)"}</p>
                                                            <p>₹ {planGST}</p>
                                                        </div>
                                                        <div className="payment-head-value">
                                                            <p>Total</p>
                                                            <p>₹ {parseFloat(formData.planBuyPrice) + (formData?.cc_plan_amount ? formData?.cc_plan_amount : 0)}</p>
                                                        </div>
                                                    </>
                                                    : <></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* services */}

                                <div className="service-container">
                                    <p>The Services Provided Under The Assistance Are As Follows:</p>
                                    <div className="service-container-block">
                                        <div className="service-square">

                                            {
                                                formData?.cc_plan_amount ? (
                                                    <div className="service-icon-circle_highlighted" >
                                                        <img src={highligtedCircle} alt="" srcset="" width={60} height={60} />
                                                    </div>
                                                ) : (
                                                    <div className="service-square">
                                                        <div className="service-icon-circle">
                                                            <img src={coverage} alt="" srcset="" width={60} height={60} />
                                                        </div>
                                                        <p>100% Claim Service</p>
                                                    </div>
                                                )
                                            }

                                            {formData?.cc_plan_amount ? <p style={{ color: "#000096" }} > Complete Care</p> : ''}

                                        </div>

                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={onlineSupport} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Online support for repairs</p>
                                        </div>

                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={rundown} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Rundown of battery</p>
                                        </div>

                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={customer} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Customer Coverage Care</p>
                                        </div>

                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={tyre} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Flat Tyre</p>
                                        </div>

                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={fuel} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Fuel Assistance</p>
                                        </div>

                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={towAssist} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Towing Assistance</p>
                                        </div>
                                    </div>
                                    <div className="service-container-block">
                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={emergency} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Emergency Assistance</p>
                                        </div>

                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={keyAssist} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Key Assistance</p>
                                        </div>

                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={taxiAssist} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Taxi Assistance</p>
                                        </div>

                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={accomadation} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Accommodation Assistance</p>
                                        </div>

                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={outward} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Outward / Forward Journey</p>
                                        </div>

                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={arrangement} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Arrangement of Rental Vehicle</p>
                                        </div>

                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={urgent} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Urgent Message Relay</p>
                                        </div>
                                    </div>
                                    <p>
                                        Customers are responsible for all expenses, including consumables,
                                        accommodation, taxis, rentals, tolls, and border charges.
                                    </p>
                                </div>
                            </div>
                        </section>

                        {/* <!-- PDF PAGE tWO --> */}


                        {
                            !formData?.is_hide_master_policy && (
                                <>
                                    <br></br>
                                    <br></br>
                                    <section className="pdf-container">
                                        <div className="header-insurer">
                                            <img src={future} alt="" srcSet="" width={100} height={50} />
                                            <p>
                                                Details of Personal Accident Cover <br />
                                                Master Policy Holder Name: BRILLJUST SOLUTIONS PVT LTD
                                            </p>
                                        </div>

                                        <div className="policy-pddf-container">
                                            <div className="block-head-value-pol">
                                                <p>Master Policy Number :</p>
                                                <p>{formData.masterPolicyNumber}</p>
                                            </div>

                                            <div className="block-head-value-pol block-head-value-pol-bold">
                                                <p>Issuing Office :</p>
                                                <p>Gurgaon</p>
                                            </div>

                                            <div className="block-head-value-pol">
                                                <p>Beneficiary's Address :</p>
                                                <p>{formData.addressLineOne + " " + formData.addressLineTwo}</p>
                                            </div>

                                            <div className="block-head-value-pol">
                                                <p>Beneficiary ID No :</p>
                                                <p>{policyNumber}</p>
                                            </div>

                                            <div className="block-head-value-pol">
                                                <p>Certificate Inception date :</p>
                                                <p>From - {fromDate + "-" + fromMonth + "-" + fromYear} Expiry date : {toDate + "-" + toMonth + "-" + toYear} </p>
                                            </div>
                                        </div>

                                        <div class="pdf-table">
                                            <div class="table-row header">
                                                <div class="table-cell table-cell-one">Name of the Member Covered</div>
                                                <div class="table-cell table-cell-two">Date of Birth/Age</div>
                                                <div class="table-cell table-cell-three ">Gender</div>
                                                <div class="table-cell table-cell-four ">Nominee Name</div>
                                                <div class="table-cell table-cell-five ">Nominee Age</div>
                                                <div class="table-cell table-cell-six ">Nominee Relationship with Member</div>
                                                <div class="table-cell table-cell-seven ">Benefits</div>
                                                <div class="table-cell table-cell-eight ">Sum Insured</div>
                                            </div>
                                            <div class="table-row">
                                                <div class="table-cell table-cell-one ">{formData.firstName + " " + formData.lastName}</div>
                                                <div class="table-cell table-cell-two ">{dob}</div>
                                                <div class="table-cell table-cell-three ">{formData.gender}</div>
                                                <div class="table-cell table-cell-four ">{formData.nomineeName}</div>
                                                <div class="table-cell table-cell-five ">{formData.nomineeAge}</div>
                                                <div class="table-cell table-cell-six ">{formData.nomineeRelation}</div>
                                                <div class="table-cell table-cell-seven ">Accidental death</div>
                                                <div class="table-cell table-cell-eight ">INR 1500000</div>
                                            </div>
                                        </div>

                                        <div className="broker-details-pdf">
                                            <div className="broker-head-value">
                                                <p>Broker Name:</p>
                                                <p>Viaansh Insurance Brokers Pvt. Ltd.</p>
                                            </div>
                                            <div className="broker-head-value">
                                                <p>Broker Email:</p>
                                                <p>contact@viaanshinsurance.com</p>
                                            </div>
                                            <div className="broker-head-value">
                                                <p>Broker Contact No:</p>
                                                <p>98112 23458</p>
                                            </div>
                                        </div>

                                        <div className="content-block-pdf">
                                            <p>Details of GPA Policy</p>
                                            <p>If the Insured person sustains any bodily injury during the policy period which directly and independently of all other causes result in death/ disablement
                                                stated below within 12 months from the date of accident resulting solely and directly from Accident then the Company shall pay to the insured the sum set in
                                                the schedule to the Insured person's nominee, beneficiary or legal representative. This certificate is effective only if the vehicle is registered in the name of
                                                insured person as on date of accident.</p>
                                        </div>

                                        <div className="content-block-pdf content-block-pdf-two">
                                            <p>Important Notes:</p>
                                            <p>
                                                1. You are covered up to age of 70 years only, any claim above age of 70 under this certificate will not be processed/ settled. <br />
                                                2. The personal accident coverage is applicable only to the owner of the vehicle for which Road Side Assistance service has been provided by the
                                                Brilljust Solutions Pvt. Ltd. <br />
                                                3. The said personal accident cover is active only whilst the owner is driving the said vehicle including mounting into/dismounting from the said vehicle. <br />
                                                4. Death or permanent total disability claims due to any other incidence would not be covered <br />
                                                5. The policy is valid for 365 days from the policy risk start date <br />
                                            </p>
                                        </div>

                                        <div className="content-block-pdf content-block-pdf-three">
                                            <p>Important Notes: <span>Please refer to policy for detail information on coverage, exclusion and other terms and conditions</span> <br /></p>
                                            <p> • Death only. (15Lakh)</p>
                                        </div>

                                        <div className="content-block-pdf content-block-pdf-three">
                                            <p>Important Exclusions in brief: <span>The insurance cover herein does not cover death, injury or disablement resulting from:</span> <br /></p>
                                            <p>
                                                • Services on duty with any armed forces. <br />
                                                • Intentional self injury, suicide or attempted suicide, insanity, venereal diseases or the influence of intoxicating drug. <br />
                                                • Medical or surgical treatment. <br />
                                                • Aviation other than as a passenger (fare-paying or otherwise) in any duly licensed standard type of aircraft anywhere in the world. <br />
                                                • Nuclear radiation or nuclear weapons related accident. <br />
                                                • War & warlike operation, the act of foreign enemy, civil war & similar risk. <br />
                                                • Child birth, pregnancy or other physical cause peculiar to the female sex. <br />
                                                • Whilst committing any breach of law with criminal intent.
                                            </p>
                                        </div>

                                        <div className="content-block-pdf content-block-pdf-three">
                                            <p>Claim Procedure: <span>An indicative document list in case of claim is as given below.</span> <br /></p>
                                        </div>

                                        <div className="distinguishTable-container">
                                            <div className="distinguishTable-header">
                                                <p>Claim Assistance</p>
                                                <p>Grievance Redressal</p>
                                            </div>
                                            <div className="distinguishTable-content">
                                                <div className="distinguishTable-content-left distinguishTable-content-block">
                                                    <p>
                                                        1. In case of any service requirement, do call our care lines below:
                                                        1800-220-233, 1860-500-3333, 022-67837800.
                                                    </p>
                                                    <p>
                                                        1. The claims would be serviced through In-House Health
                                                        Administrator ,Future Generali Health(FGH )Team. The claim
                                                        documents can be submitted directly to the below mentioned
                                                        address.
                                                        Future Generali Health (FGH) Future Generali India Insurance
                                                        Company Ltd Office No. 3, 3rd Floor, Building A, G - O - Square
                                                        Aundh Hinjewadi Link Road, Wakad Pune, Maharashtra - 411 057
                                                    </p>
                                                    <p>
                                                        1. It is mandatory to intimate the accidents to Future Generali
                                                        through: Email ID - fgh@futuregenerali.in
                                                    </p>
                                                    <p>
                                                        1. For any claim related queries please call : Toll free number - 1800
                                                        209 1016 / 1800 103 8889 Toll free Fax - 1800 209 1017 / 1800
                                                        103 9998 Email ID - fgh@futuregenerali.in
                                                    </p>
                                                </div>
                                                <div className="distinguishTable-content-right distinguishTable-content-block">
                                                    <p>
                                                        L1. You can call on our helpline 1800-220-233 / 1860-500-3333 /             022-67837800 or
                                                        send us an email fgcare@futuregenerali.in or visit branch grievance
                                                        redressal officer (GRO) walk-in to any of   our nearest branches and request to
                                                        meet the GRO.
                                                    </p>
                                                    <p>
                                                        L2. If the solution provided to you does not meet your expectations, you can write
                                                        directly to our corporate gro. send us an email fggro@futuregenerali.in or call
                                                        us on +91-79001 97777 or send your complaint to our corporate grievance
                                                        redressal officer Mr. Hari shankar mishra Address
                                                        Future Generali India Insurance company ltd. lodha i - think techno campus, b
                                                        wing - 2nd floor, pokhran road - 2, off eastern express highway behind tcs,
                                                        thane west - 400607
                                                    </p>
                                                    <p>
                                                        L3. If you are still not satisfied with the solution provided, you can email our CEO's
                                                        office. EMAIL OUR CEO's OFFICE fgceo@futuregenerali.in
                                                    </p>
                                                    <div className="download-link-footer">
                                                        <p>You can download our Grievance Redressal Procedure.</p>
                                                        <a href="https://general.futuregenerali.in/footer-pdf/grievance-redressal-procedures.pdf" target="_blank">https://general.futuregenerali.in/footer-pdf/grievance-redressal-procedures.pdf</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </>
                            )
                        }

                    </>
                )

                const doc = new jsPDF('p', 'pt', 'a4', true)
                await doc.addFont(PoppinsRegular, 'Poppins', 'normal');
                doc.setFont('Poppins');

                doc.html(ReactDOMServer.renderToString(element), {
                    html2canvas: {
                        scale: 0.95,
                        useCORS: true,
                    },
                    processData: false,
                    contentType: false,
                    margin: [0, 20, 0, 20],
                    autoPaging: 'text',
                    callback: function (doc) {
                        if (is_merge_pdf) {
                            let pdfbase64 = doc.output('datauristring');
                            pdfbase64 = pdfbase64.split(",")[1];
                            resolve(pdfbase64)
                        } else {
                            doc.save(policyNumber.replace('/', "_") + '.pdf')
                            resolve()
                        }
                    }
                })
            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
}

export const exportPdfPageOne = async (formData, policyNumber, is_merge_pdf = false) => {
    return new Promise(
        async (resolve, reject) => {
            try {
                let startDate = moment(formData.policyStartDate);
                let dob = moment(formData.dob).format("D-MM-YYYY");
                let endDate = moment(moment(formData.policyStartDate).add(1, 'y')).subtract(1, 'day');

                let fromYear = moment(startDate).format('YYYY');
                let fromMonth = moment(startDate).format('MMMM');
                let fromDate = moment(startDate).format('D');

                let toYear = moment(endDate).format('YYYY');
                let toMonth = moment(endDate).format('MMMM');
                let toDate = moment(endDate).format('D');
                // let planGST = Math.floor(parseInt(formData.planBuyPrice) * 0.18);
                // let basicPlanAmt = parseInt(formData.planBuyPrice) - planGST;

                let basicPlanAmt = (parseInt(formData.planBuyPrice)) / (1 + 0.18);
                let basicCcAmt = formData.cc_plan_amount ? (parseInt((parseInt(formData.cc_plan_amount)) / (1 + 0.18))) : 0;
                let planGST = (parseInt(formData.planBuyPrice) +
                    (formData.cc_plan_amount ? parseInt(formData?.cc_plan_amount) : 0))
                    - basicPlanAmt
                    - basicCcAmt;
                basicPlanAmt = basicPlanAmt.toFixed(2);
                planGST = planGST.toFixed(2);

                let element = (
                    <>
                        {/* <!-- PDF PAGE ONE --> */}
                        <section className="pdf-container">
                            <div className="header-container-pdf">
                                <img src={logo} width={230} height={50} alt="" srcSet="" />
                                <div className="alliance_section">
                                    <p className="alliance_heading">
                                        Brilljust Solutions Private Limited
                                    </p>
                                    <p>
                                        ALT-F, Suncity Success Tower, <br /> Sec-65 Golf Course Ext.{" "}
                                        <br /> Road,Gurgaon (hr) :122005
                                    </p>
                                </div>
                            </div>

                            <div className="pdfContent">
                                <div className="pdfContent-header">
                                    <div>
                                        <p className="headTitle">
                                            Product: <span>{formData.productName}</span>
                                        </p>
                                        <p className="headTitle-gray">Master Policy Number: {formData.masterPolicyNumber}</p>
                                    </div>

                                    <div className="policyHolderDetailsBlock policyHolderDetailsBlock_blue">
                                        <div className="policyHolderDetail-container">
                                            <div className="titleDescription-policy titleDescription-policy_copy">
                                                <p>Certificate Number</p>
                                                <p>{policyNumber}</p>
                                            </div>
                                            <div className="titleDescription-policy"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex-container-pdf">
                                    <div className="flex-container-pdf-left">
                                        <div className="policyHolderDetails">
                                            <div className="policyHolderDetailsBlock">
                                                <div className="policyHolderDetail-container">
                                                    <div className="titleDescription-policy">
                                                        <p>Insured Name</p>
                                                        <p>{formData.firstName + " " + formData.lastName}</p>
                                                    </div>
                                                    <div className="titleDescription-policy">
                                                        <p>Mobile Number</p>
                                                        <p>{formData.mobileNumber}</p>
                                                    </div>
                                                </div>
                                                <div className="policyHolderDetail-container">
                                                    <div className="titleDescription-policy">
                                                        <p>Email ID</p>
                                                        <p>{formData.emailId}</p>
                                                    </div>
                                                    <div className="titleDescription-policy"></div>
                                                </div>
                                                <div className="policyHolderDetail-container">
                                                    <div className="titleDescription-policy">
                                                        <p>Address</p>
                                                        <p>
                                                            {formData.addressLineOne + " " + formData.addressLineTwo}
                                                        </p>
                                                    </div>
                                                    <div className="titleDescription-policy"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="vehicle-details-block">
                                            <div className="vehicle-details-header">
                                                <img src={tow} alt="" />
                                                <p>Vehicle Details</p>
                                            </div>
                                            <div className="title-desc-vehicle">
                                                <p>Registration Number</p>
                                                <p>{formData.businessType?.toLowerCase() == "new" ? "NEW" : formData.vehicleNumber}</p>
                                            </div>
                                            <div className="title-desc-vehicle">
                                                <p>Vehicle Type</p>
                                                <p>{formData?.vehicleType ? formData?.vehicleType : "Two Wheeler"}</p>
                                            </div>
                                            <div className="title-desc-vehicle">
                                                <p>Make</p>
                                                <p>{formData.make}</p>
                                            </div>
                                            <div className="title-desc-vehicle">
                                                <p>Model / Variant</p>
                                                <p>{formData.model}</p>
                                            </div>
                                            <div className="title-desc-vehicle">
                                                <p>Engine Number</p>
                                                <p>{formData.engineNumber}</p>
                                            </div>
                                            <div className="title-desc-vehicle">
                                                <p>Chassis Number</p>
                                                <p>{formData.chassisNumber}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex-container-pdf-right">
                                        <div className="policyDetails">
                                            <div className="calendar_container_pdf">
                                                <div className="calendar">
                                                    <div className="calendar-year">
                                                        <p>{fromYear}</p>
                                                    </div>
                                                    <div className="calendar-details">
                                                        <p>Certificate Start Date</p>
                                                        <p>{fromDate}</p>
                                                        <p>{fromMonth}</p>
                                                    </div>
                                                </div>
                                                <div className="calendar">
                                                    <div className="calendar-year">
                                                        <p>{toYear}</p>
                                                    </div>
                                                    <div className="calendar-details">
                                                        <p>Certificate End Date</p>
                                                        <p>{toDate}</p>
                                                        <p>{toMonth}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="comp-details-container">
                                            <div className="comp-details">
                                                <div className="comp-details-block">
                                                    <div className="alliance_head_details">
                                                        <p>Alliance Assure Private Limited</p>
                                                        <img src={allianceLogo} alt="" />
                                                    </div>

                                                    <p style={{ color: "#1A1B1D" }}>For Any Assistance For RSA</p>

                                                    <p>Call or Email Us:</p>

                                                    <div className="support_container_pdf">
                                                        <img src={call} alt="" srcset="" />
                                                        <p>1800 212 7559</p>
                                                    </div>

                                                    <div className="support_container_pdf">
                                                        <img src={mail} alt="" srcset="" />
                                                        <p>support@allianceassure.com</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="policy-container-pdf">
                                                <p>{formData.is_opt_gst ? "Plan Details" : "Payment Detail"}</p>
                                                <div className="payment-head-value">
                                                    <p>{formData.is_opt_gst ? `PLAN-${formData.planBuyPrice}` : "RSA BASE"} </p>
                                                    {!formData.is_opt_gst &&
                                                        <p>₹ {basicPlanAmt}</p>
                                                    }
                                                </div>
                                                {formData?.cc_plan_amount ?
                                                    <>
                                                        <div className="payment-head-value">
                                                            <p>{formData.is_opt_gst ? `Complete Care Plan ` : "Complete Care Plan"}
                                                                {formData.is_opt_gst ? <img src={tick} alt="" srcset="" /> : ''} </p>
                                                            {!formData.is_opt_gst &&
                                                                <p>₹ {basicCcAmt}</p>
                                                            }
                                                        </div>
                                                    </>
                                                    : ''
                                                }
                                                {!formData.is_opt_gst ?
                                                    <>
                                                        <div className="payment-head-value">
                                                            <p>{formData.state === "HARYANA" ? "CGST (9%) + SGST (9%)" : "IGST (18%)"}</p>
                                                            <p>₹ {planGST}</p>
                                                        </div>
                                                        <div className="payment-head-value">
                                                            <p>Total</p>
                                                            <p>₹ {parseFloat(formData.planBuyPrice) + (formData?.cc_plan_amount ? formData?.cc_plan_amount : 0)}</p>
                                                        </div>
                                                    </>
                                                    : <></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* services */}

                                <div className="service-container">
                                    <p>The Services Provided Under The Assistance Are As Follows:</p>
                                    <div className="service-container-block">
                                        <div className="service-square">
                                            {
                                                formData?.cc_plan_amount ? (
                                                    <div className="service-icon-circle_highlighted" >
                                                        <img src={highligtedCircle} alt="" srcset="" width={60} height={60} />
                                                    </div>
                                                ) : (
                                                    <div className="service-square">
                                                        <div className="service-icon-circle">
                                                            <img src={coverage} alt="" srcset="" width={60} height={60} />
                                                        </div>
                                                        <p>100% Claim Service</p>
                                                    </div>
                                                )
                                            }
                                            {formData?.cc_plan_amount ? <p style={{ color: "#000096" }} > Complete Care</p> : ''}
                                        </div>

                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={onlineSupport} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Online support for repairs</p>
                                        </div>

                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={rundown} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Rundown of battery</p>
                                        </div>

                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={customer} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Customer Coverage Care</p>
                                        </div>

                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={tyre} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Flat Tyre</p>
                                        </div>

                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={fuel} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Fuel Assistance</p>
                                        </div>

                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={towAssist} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Towing Assistance</p>
                                        </div>
                                    </div>
                                    <div className="service-container-block">
                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={emergency} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Emergency Assistance</p>
                                        </div>

                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={keyAssist} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Key Assistance</p>
                                        </div>

                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={taxiAssist} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Taxi Assistance</p>
                                        </div>

                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={accomadation} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Accommodation Assistance</p>
                                        </div>

                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={outward} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Outward / Forward Journey</p>
                                        </div>

                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={arrangement} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Arrangement of Rental Vehicle</p>
                                        </div>

                                        <div className="service-square">
                                            <div className="service-icon-circle">
                                                <img src={urgent} alt="" srcset="" width={60} height={60} />
                                            </div>
                                            <p>Urgent Message Relay</p>
                                        </div>
                                    </div>
                                    <p>
                                        Customers are responsible for all expenses, including consumables,
                                        accommodation, taxis, rentals, tolls, and border charges.
                                    </p>
                                </div>
                            </div>
                        </section>
                    </>
                )

                const doc = new jsPDF('p', 'pt', 'a4', true)
                await doc.addFont(PoppinsRegular, 'Poppins', 'normal');
                doc.setFont('Poppins');

                doc.html(ReactDOMServer.renderToString(element), {
                    html2canvas: {
                        scale: 0.95,
                        useCORS: true,
                    },
                    processData: false,
                    contentType: false,
                    margin: [0, 20, 0, 20],
                    autoPaging: 'text',
                    callback: function (doc) {
                        if (is_merge_pdf) {
                            let pdfbase64 = doc.output('datauristring');
                            pdfbase64 = pdfbase64.split(",")[1];
                            resolve(pdfbase64)
                        } else {
                            doc.save(policyNumber.replace('/', "_") + '.pdf')
                            resolve()
                        }
                    }
                })
            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
}

export const exportPdfPageTwo = async (formData, policyNumber, is_merge_pdf = false) => {
    return new Promise(
        async (resolve, reject) => {
            try {
                let startDate = moment(formData.policyStartDate);
                let dob = moment(formData.dob).format("D-MM-YYYY");
                let endDate = moment(moment(formData.policyStartDate).add(1, 'y')).subtract(1, 'day');

                let fromYear = moment(startDate).format('YYYY');
                let fromMonth = moment(startDate).format('MMMM');
                let fromDate = moment(startDate).format('D');

                let toYear = moment(endDate).format('YYYY');
                let toMonth = moment(endDate).format('MMMM');
                let toDate = moment(endDate).format('D');
                // let planGST = Math.floor(parseInt(formData.planBuyPrice) * 0.18);
                // let basicPlanAmt = parseInt(formData.planBuyPrice) - planGST;

                let basicPlanAmt = (parseInt(formData.planBuyPrice)) / (1 + 0.18);
                let planGST = parseInt(formData.planBuyPrice) - basicPlanAmt;
                basicPlanAmt = basicPlanAmt.toFixed(2);
                planGST = planGST.toFixed(2);

                let element = (
                    <>
                        {/* pdf page two */}
                        <section className="pdf-container">
                            <div className="header-insurer">
                                <img src={future} alt="" srcSet="" width={100} height={50} />
                                <p>
                                    Details of Personal Accident Cover <br />
                                    Master Policy Holder Name: BRILLJUST SOLUTIONS PVT LTD
                                </p>
                            </div>

                            <div className="policy-pddf-container">
                                <div className="block-head-value-pol">
                                    <p>Master Policy Number :</p>
                                    <p>{formData.masterPolicyNumber}</p>
                                </div>

                                <div className="block-head-value-pol block-head-value-pol-bold">
                                    <p>Issuing Office :</p>
                                    <p>Gurgaon</p>
                                </div>

                                <div className="block-head-value-pol">
                                    <p>Beneficiary's Address :</p>
                                    <p>{formData.addressLineOne + " " + formData.addressLineTwo}</p>
                                </div>

                                <div className="block-head-value-pol">
                                    <p>Beneficiary ID No :</p>
                                    <p>{policyNumber}</p>
                                </div>

                                <div className="block-head-value-pol">
                                    <p>Certificate Inception date :</p>
                                    <p>From - {fromDate + "-" + fromMonth + "-" + fromYear} Expiry date : {toDate + "-" + toMonth + "-" + toYear} </p>
                                </div>
                            </div>

                            <div class="pdf-table">
                                <div class="table-row header">
                                    <div class="table-cell table-cell-one">Name of the Member Covered</div>
                                    <div class="table-cell table-cell-two">Date of Birth/Age</div>
                                    <div class="table-cell table-cell-three ">Gender</div>
                                    <div class="table-cell table-cell-four ">Nominee Name</div>
                                    <div class="table-cell table-cell-five ">Nominee Age</div>
                                    <div class="table-cell table-cell-six ">Nominee Relationship with Member</div>
                                    <div class="table-cell table-cell-seven ">Benefits</div>
                                    <div class="table-cell table-cell-eight ">Sum Insured</div>
                                </div>
                                <div class="table-row">
                                    <div class="table-cell table-cell-one ">{formData.firstName + " " + formData.lastName}</div>
                                    <div class="table-cell table-cell-two ">{dob}</div>
                                    <div class="table-cell table-cell-three ">{formData.gender}</div>
                                    <div class="table-cell table-cell-four ">{formData.nomineeName}</div>
                                    <div class="table-cell table-cell-five ">{formData.nomineeAge}</div>
                                    <div class="table-cell table-cell-six ">{formData.nomineeRelation}</div>
                                    <div class="table-cell table-cell-seven ">Accidental death</div>
                                    <div class="table-cell table-cell-eight ">INR 1500000</div>
                                </div>
                            </div>

                            <div className="broker-details-pdf">
                                <div className="broker-head-value">
                                    <p>Broker Name:</p>
                                    <p>Viaansh Insurance Brokers Pvt. Ltd.</p>
                                </div>
                                <div className="broker-head-value">
                                    <p>Broker Email:</p>
                                    <p>contact@viaanshinsurance.com</p>
                                </div>
                                <div className="broker-head-value">
                                    <p>Broker Contact No:</p>
                                    <p>98112 23458</p>
                                </div>
                            </div>

                            <div className="content-block-pdf">
                                <p>Details of GPA Policy</p>
                                <p>If the Insured person sustains any bodily injury during the policy period which directly and independently of all other causes result in death/ disablement
                                    stated below within 12 months from the date of accident resulting solely and directly from Accident then the Company shall pay to the insured the sum set in
                                    the schedule to the Insured person's nominee, beneficiary or legal representative. This certificate is effective only if the vehicle is registered in the name of
                                    insured person as on date of accident.</p>
                            </div>

                            <div className="content-block-pdf content-block-pdf-two">
                                <p>Important Notes:</p>
                                <p>
                                    1. You are covered up to age of 70 years only, any claim above age of 70 under this certificate will not be processed/ settled. <br />
                                    2. The personal accident coverage is applicable only to the owner of the vehicle for which Road Side Assistance service has been provided by the
                                    Brilljust Solutions Pvt. Ltd. <br />
                                    3. The said personal accident cover is active only whilst the owner is driving the said vehicle including mounting into/dismounting from the said vehicle. <br />
                                    4. Death or permanent total disability claims due to any other incidence would not be covered <br />
                                    5. The policy is valid for 365 days from the policy risk start date <br />
                                </p>
                            </div>

                            <div className="content-block-pdf content-block-pdf-three">
                                <p>Important Notes: <span>Please refer to policy for detail information on coverage, exclusion and other terms and conditions</span> <br /></p>
                                <p> • Death only. (15Lakh)</p>
                            </div>

                            <div className="content-block-pdf content-block-pdf-three">
                                <p>Important Exclusions in brief: <span>The insurance cover herein does not cover death, injury or disablement resulting from:</span> <br /></p>
                                <p>
                                    • Services on duty with any armed forces. <br />
                                    • Intentional self injury, suicide or attempted suicide, insanity, venereal diseases or the influence of intoxicating drug. <br />
                                    • Medical or surgical treatment. <br />
                                    • Aviation other than as a passenger (fare-paying or otherwise) in any duly licensed standard type of aircraft anywhere in the world. <br />
                                    • Nuclear radiation or nuclear weapons related accident. <br />
                                    • War & warlike operation, the act of foreign enemy, civil war & similar risk. <br />
                                    • Child birth, pregnancy or other physical cause peculiar to the female sex. <br />
                                    • Whilst committing any breach of law with criminal intent.
                                </p>
                            </div>

                            <div className="content-block-pdf content-block-pdf-three">
                                <p>Claim Procedure: <span>An indicative document list in case of claim is as given below.</span> <br /></p>
                            </div>

                            <div className="distinguishTable-container">
                                <div className="distinguishTable-header">
                                    <p>Claim Assistance</p>
                                    <p>Grievance Redressal</p>
                                </div>
                                <div className="distinguishTable-content">
                                    <div className="distinguishTable-content-left distinguishTable-content-block">
                                        <p>
                                            1. In case of any service requirement, do call our care lines below:
                                            1800-220-233, 1860-500-3333, 022-67837800.
                                        </p>
                                        <p>
                                            1. The claims would be serviced through In-House Health
                                            Administrator ,Future Generali Health(FGH )Team. The claim
                                            documents can be submitted directly to the below mentioned
                                            address.
                                            Future Generali Health (FGH) Future Generali India Insurance
                                            Company Ltd Office No. 3, 3rd Floor, Building A, G - O - Square
                                            Aundh Hinjewadi Link Road, Wakad Pune, Maharashtra - 411 057
                                        </p>
                                        <p>
                                            1. It is mandatory to intimate the accidents to Future Generali
                                            through: Email ID - fgh@futuregenerali.in
                                        </p>
                                        <p>
                                            1. For any claim related queries please call : Toll free number - 1800
                                            209 1016 / 1800 103 8889 Toll free Fax - 1800 209 1017 / 1800
                                            103 9998 Email ID - fgh@futuregenerali.in
                                        </p>
                                    </div>
                                    <div className="distinguishTable-content-right distinguishTable-content-block">
                                        <p>
                                            L1. You can call on our helpline 1800-220-233 / 1860-500-3333 /             022-67837800 or
                                            send us an email fgcare@futuregenerali.in or visit branch grievance
                                            redressal officer (GRO) walk-in to any of   our nearest branches and request to
                                            meet the GRO.
                                        </p>
                                        <p>
                                            L2. If the solution provided to you does not meet your expectations, you can write
                                            directly to our corporate gro. send us an email fggro@futuregenerali.in or call
                                            us on +91-79001 97777 or send your complaint to our corporate grievance
                                            redressal officer Mr. Hari shankar mishra Address
                                            Future Generali India Insurance company ltd. lodha i - think techno campus, b
                                            wing - 2nd floor, pokhran road - 2, off eastern express highway behind tcs,
                                            thane west - 400607
                                        </p>
                                        <p>
                                            L3. If you are still not satisfied with the solution provided, you can email our CEO's
                                            office. EMAIL OUR CEO's OFFICE fgceo@futuregenerali.in
                                        </p>
                                        <div className="download-link-footer">
                                            <p>You can download our Grievance Redressal Procedure.</p>
                                            <a href="https://general.futuregenerali.in/footer-pdf/grievance-redressal-procedures.pdf" target="_blank">https://general.futuregenerali.in/footer-pdf/grievance-redressal-procedures.pdf</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                )

                const doc = new jsPDF('p', 'pt', 'a4', true)
                await doc.addFont(PoppinsRegular, 'Poppins', 'normal');
                doc.setFont('Poppins');

                doc.html(ReactDOMServer.renderToString(element), {
                    html2canvas: {
                        scale: 0.95,
                        useCORS: true,
                    },
                    processData: false,
                    contentType: false,
                    margin: [0, 20, 0, 20],
                    autoPaging: 'text',
                    callback: function (doc) {
                        if (is_merge_pdf) {
                            let pdfbase64 = doc.output('datauristring');
                            pdfbase64 = pdfbase64.split(",")[1];
                            resolve(pdfbase64)
                        } else {
                            doc.save(policyNumber.replace('/', "_") + '.pdf')
                            resolve()
                        }
                    }
                })
            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
}

export const getBase64 = (file) => {
    return new Promise(
        async (resolve, reject) => {
            try {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    resolve(reader.result.split(",")[1])
                };
                reader.onerror = function (error) {
                    reject(error)
                };
            } catch (error) {
                reject(error)
            }
        })
}

export const base64ToUint8Array = (base64String) => {
    const binaryString = window.atob(base64String);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
};