import React from 'react'
import img from "../../../Assets/Images/paymentConfirmation.svg"
import Button from '../../InputField/Button/Button'
import './PaymentConfirmation.css'
import ButtonLoader from "../../Layouts/Loader/Button Loader/ButtonLoader";

const PaymentConfirmation = (props) => {

    const { closeModal, paymentConfirmed, isLoading, isApiLoaded } = props;

    //   // disabled-inputField

    return (
        <>
            <div className="content-block-redirect content-block-redirect-payment">
                <img src={img} alt="" srcSet="" />
                <p className="msg-edirect-succes msg-edirect-succes-primary">Payment Confirmation!</p>
                <p className="msg-edirect">
                    By Clicking <span>'Pay Now'</span> completes your transaction. Do you want to proceed with payment?
                </p>
                {/* <div className={isLoading ? "disabled-inputField btn-container-modal" : "btn-container-modal"}> */}
                <div className="btn-container-modal">
                    <Button
                        text="Cancel"
                        event={closeModal}
                        className="button secondry_btn btn-vehicledetails"
                    />
                    {
                        !isApiLoaded ? (
                            <Button
                                text="Yes, Pay Now"
                                event={paymentConfirmed}
                                className="button btn-vehicledetails"
                            />
                        ) : (
                            <ButtonLoader className="button btn-vehicledetails" />
                        )
                    }

                </div>
            </div>
        </>
    )
}

export default PaymentConfirmation
