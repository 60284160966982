import jsPDF from 'jspdf'
import ReactDOMServer from 'react-dom/server'
import logo from "../Assets/Images/brillJustjpg.png"
import '../Components/PDF-Container/InvoicePdf.css'
import PoppinsRegular from "../Assets/Font/Merriweather,Poppins/Poppins/Poppins-Regular.ttf";
import moment from 'moment';

export const  exportInvoicePDF = async (formData, policyPremiumValues, userInputValues) => {
    return new Promise(
        async (resolve, reject) => {
            //igst on plan buy price
            // let planGST = Math.floor(parseInt(formData.proposal_body.planBuyPrice) * 0.18);
            // let basicPlanAmt = parseInt(formData.proposal_body.planBuyPrice) - planGST;

            let basicPlanAmt = (parseInt(formData.proposal_body.planBuyPrice)) / (1 + 0.18);
            let basicCcAmt = formData.cc_plan_amount ? (parseInt((parseInt(formData.cc_plan_amount)) / (1 + 0.18))) : 0;
            let planGST = parseInt(formData.proposal_body.planBuyPrice) - basicPlanAmt;
            basicPlanAmt = basicPlanAmt.toFixed(2);
            planGST = planGST.toFixed(2);
            let ccGst = formData.cc_plan_amount ? (parseInt(formData.cc_plan_amount) - basicCcAmt) : 0
            let element = (
                <>
                    <section className="pdf-container">
                        <div className="pdfContent-invoiceDetails">
                            <button className="block-header">Business Summary Sheet</button>
                            <div className="detail-block-container">

                                <div className="summary-block">
                                    <p>Particulars</p>
                                    <p>Summary INS</p>
                                    <p>Summary BSPL</p>
                                    {formData?.cc_plan_amount ?
                                        <p>Summary CCP</p>
                                        : ''
                                    }
                                </div>

                                <div className="summary-block">
                                    <p>Net Amount</p>
                                    <p>₹ {policyPremiumValues.packagePremium}</p>
                                    <p>₹ {basicPlanAmt}</p>
                                    {formData?.cc_plan_amount ?
                                        <p>₹ {basicCcAmt}</p>
                                        : ''
                                    }
                                </div>

                                <div className="summary-block">
                                    <p>GST</p>
                                    <p>₹ {policyPremiumValues.igst}</p>
                                    <p>₹ {planGST}</p>
                                    {formData?.cc_plan_amount ?
                                        <p>₹ {ccGst}</p>
                                        : ''
                                    }
                                </div>

                                <div className="summary-block">
                                    <p>Total</p>
                                    <p>₹ {policyPremiumValues.totalPremium}</p>
                                    <p>₹ {formData.proposal_body.planBuyPrice}</p>
                                    {formData?.cc_plan_amount ?
                                        <p>₹ {formData?.cc_plan_amount}</p>
                                        : ''
                                    }
                                </div>

                            </div>

                            <div className="detail-block-container detail-block-blue">

                                <div className="summary-block-bottom summary-block-bottom-bold">
                                    <p>Total Amount</p>
                                </div>

                                <div className="summary-block-bottom">
                                    <p>₹ {parseInt(parseFloat(policyPremiumValues.packagePremium) + parseFloat(basicPlanAmt) + parseFloat(basicCcAmt))}</p>
                                </div>

                                <div className="summary-block-bottom">
                                    <p>₹ {parseInt(parseFloat(policyPremiumValues.igst) + parseFloat(planGST) + parseFloat(ccGst))}</p>
                                </div>

                                <div className="summary-block-bottom">
                                    <p>₹ {policyPremiumValues.totalPremium + parseInt(formData.proposal_body.planBuyPrice) + parseInt(formData.cc_plan_amount ? formData.cc_plan_amount : 0)}</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )

            const doc = new jsPDF('p', 'pt', 'a4', true)
            await doc.addFont(PoppinsRegular, 'Poppins', 'normal');
            doc.setFont('Poppins');

            doc.html(ReactDOMServer.renderToString(element), {
                html2canvas: {
                    scale: 0.95
                },
                processData: false,
                contentType: false,
                margin: [0, 20, 0, 20],
                callback: function (doc) {
                    var string = doc.output('datauristring');
                    string = string.split(",")[1];
                    resolve(string)
                }
            })
        })
}