import "../../../../node_modules/react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import policyRenewal from "../../../Assets/Images/policyRenewal.svg";
import policyClaim from "../../../Assets/Images/policyClaim.svg";
import policyCorrection from "../../../Assets/Images/policyCorrection.svg";
import "./Login.css";
import InputFields from "../../InputField/TextField/InputField";
import Button from "../../InputField/Button/Button";
import OtpInput from "react-otp-input";
import { isLoggedInAction } from "../../../Redux/Reducers/loginAuthentication";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import loader2 from "../../../Assets/Loader/Loader.gif";
import { loginAction } from "../../../Redux/Actions/LoginAction";
import Navbar from "../../Layouts/Navbar/Navbar";
import Loader from "../../Layouts/Loader/Loader";

const Login = () => {
  const [ShowOtpField, setShowOtpField] = useState(false);
  const [otp, setOtp] = useState("");
  const [inputId, setInputId] = useState("");
  const [errors, setErrors] = useState("");
  const [IsLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  // navigation
  const navigate = useNavigate();

  // retriving data
  const { state } = useLocation();


  // logout

  useEffect(() => {
    dispatch(
      isLoggedInAction({
        isLoggedIn: false,
        loginData: null,
      })
    );
  }, []);

  const validateMobile = () => {
    let newErrors = {};
    let regexMobile = /^[2-9]{1}[0-9]{9}$/;
    let regexOtp = /^[1-9]{1}[0-9]{5}$/;
    if (!ShowOtpField) {
      if (!inputId) {
        newErrors.phoneNumber = "Phone Number is required";
      }
      if (inputId && !regexMobile.test(inputId)) {
        newErrors.phoneNumber = "Mobile number is Invalid";
      }
    } else {
      if (!otp) {
        newErrors.otp = "Otp is required";
      }
      if (otp && !regexOtp.test(otp)) {
        newErrors.otp = "OTP is Invalid";
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const sendOtp = async () => {
    const isValidMobile = validateMobile();
    if (isValidMobile) {
      setLoading(true);
      //call send otp API
      let response = await dispatch(
        loginAction({
          data: {
            mobile_no: inputId,
          },
        })
      );
      if (response?.payload?.status) {
        setLoading(false);
        toast(
          response?.payload?.msg
            ? response?.payload?.msg
            : "Something went Wrong, try after sometime!",
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        setShowOtpField(true);
      } else {
        setLoading(false);
        toast.error(
          response?.payload?.msg
            ? response?.payload?.msg
            : "Something went Wrong, try after sometime!",
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    }
  };

  const verifyOTP = async () => {
    //call API for verifyOTp
    const isValidOtp = validateMobile();
    if (isValidOtp) {
      //call verify otp API
      let response = await dispatch(
        loginAction({
          data: {
            mobile_no: inputId,
            otp: otp,
          },
        })
      );
      if (response?.payload?.status) {
        setLoading(false);
        toast("Logged In !", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setIsLoggedIn(true);
        dispatch(
          isLoggedInAction({
            isLoggedIn: true,
            loginData: response?.payload?.data,
          })
        );
        navigate("/View-Plans");
      } else {
        setLoading(false);
        toast.error(
          response?.payload?.msg
            ? response?.payload?.msg
            : "Something went Wrong, try after sometime!",
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    }
  };

  const handlePhoneNumber = (e) => {
    const { id, value, name } = e?.target || {};
    if (value.length <= 10) {
      setInputId(value);
    }
  };

  useEffect(() => {
    if (loading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [loading]);

  return (
    <>
      <ToastContainer />
      {loading ? <Loader /> : null}
      {/* <div id="cover-spin" style={{ display: loading ? "" : "none" }}>
        <img src={loader2} className="gifLoader"></img>
      </div> */}
      <Navbar />
      <section className="loginContainer">
        <div className="loginParentContainer maxWidth ">
          <div className="login-child login-child-content ">
            <p className="login-child-head login-child-head-hide">
              Securely Manage <br /> Your Insurance Policies.
            </p>
            <p className="login-child-text login-child-text-margin  ">
              Manage all your insurance policies at one place. We can help you
              stay organized and ensure you have a clear overview of your
              coverage.
            </p>
            <div
              className={
                !ShowOtpField
                  ? "feature_container-login"
                  : "feature_container-login hideInMob-login"
              }
            >
              <div className="content-login-left">
                <img src={policyRenewal} alt="" srcSet="" />
                <p>
                  Policy <br /> Renewal
                </p>
              </div>
              <div className="content-login-left">
                <img src={policyClaim} alt="" srcSet="" />
                <p>
                  Policy <br /> Claims
                </p>
              </div>
              <div className="content-login-left">
                <img src={policyCorrection} alt="" srcSet="" />
                <p>
                  Policy <br /> Correction
                </p>
              </div>
            </div>
          </div>

          {!ShowOtpField && (
            <div className="login-child login-child-form ">
              <div className="form-login-head">
                <p className="login-child-head">Login</p>
                <p className="login-child-text login-child-text-two ">
                  Please enter registered mobile number.
                </p>
              </div>
              <InputFields
                placeholder="My Mobile Number"
                id="loginInputs"
                type="number"
                name="userInputs"
                value={inputId}
                errState={errors?.phoneNumber ? true : false}
                errMsg={errors?.phoneNumber}
                errClassName="input-err"
                onChangeEvent={(e) => handlePhoneNumber(e)}
              />
              <Button
                text="Send OTP"
                className="button login-btn "
                event={(e) => sendOtp()}
              />
              <p className="login-child-text login-child-text-two ">
                By clicking on Sent OTP, I agree to &nbsp;
                <span className="footer_hyper_link footer_hyper_link-login underline_text">
                  Terms & Conditions
                </span>
              </p>
            </div>
          )}

          {ShowOtpField && (
            <div className="login-child login-child-form ">
              <div className="form-login-head">
                <p className="login-child-head">OTP Verification</p>
                <p className="login-child-text login-child-text-two ">
                  Please enter OTP sent on &nbsp;
                  <span style={{ color: "var(--primary)" }}>{inputId}</span>
                </p>
              </div>

              <div className="otpInput-container">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderInput={(props) => <input {...props} />}
                />
              </div>
              {errors.otp ? <p className="err-msg">{errors.otp}</p> : ""}

              <Button
                text="Verify OTP"
                className="button login-btn "
                event={(e) => verifyOTP()}
              />

              <div className="footer-login-text">
                <p className="login-child-text login-child-text-two login-child-text-three">
                  Don’t get OTP?
                  <span className="footer_hyper_link footer_hyper_link-login underline_text">
                    Resend now.
                  </span>
                </p>

                <span style={{ color: "var(--primary)", fontSize: ".75rem" }}>
                  Entered wrong number?
                </span>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Login;
