import { createAsyncThunk } from "@reduxjs/toolkit";

export const decryptPdfAction = createAsyncThunk(
    "decryptPdfAction",
    async (data) => {
        const response = await fetch(
            `https://api.brilljust.com/decrypt-pdf`,
            {
                method: "POST",
                mode: 'cors',
                body: JSON.stringify(data),
                headers: new Headers({
                    'Content-Type': 'application/json'
                }),
            }
        );
        return response.json();
    }
);