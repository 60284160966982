import React, { useEffect } from "react";
import "./CarDetails.css";
import Breadcrumbs from "../../../../Layouts/Breadcrumbs/Breadcrumbs";
import InputFields from "../../../../InputField/TextField/InputField";
import RadioButton from "../../../../InputField/RadioButton/RadioButton";
import Button from "../../../../InputField/Button/Button";
import IrdaiBlock from "../IRDAI Block/IrdaiBlock";
import { useDispatch } from "react-redux";
import { resetVehicleData } from "../../../../../Redux/Reducers/MmvReducer";

const CarDetails = (props) => {
  const {
    FormData,
    HanldeFormData,
    setComponentToRender,
    handleBackBtn,
    errors,
  } = props;

  let businessTypeList = [
    {
      label: "Rollover",
      groupName: "businessType",
      value: "Rollover",
      id: "Rollover",
      changeEvent: HanldeFormData,
    },
    {
      label: "New",
      groupName: "businessType",
      value: "New",
      id: "New",
      changeEvent: HanldeFormData,
    },
  ];

  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(resetVehicleData());
  // }, []);

  return (
    <>
      <section className="carDetails-container">
        <div className="carDetails-parent maxWidth">
          <div className="breadCrumb-parent ">
            <Breadcrumbs event={handleBackBtn} componentName="cardetails" />
          </div>
          <p className="headingWithSpan">
            Vehicle <span>Details.</span>
          </p>
          <div className="form-container-cardetails">
            <p className="prod-name prod-name-cardetails">
              Vehicle <span>Insurance</span>
            </p>
            <div className="form-content-container">
              <div className="form-side-vehicledetails">
                <div className="inp-container-vehicledetails radio-vehicledetails  ">
                  <label htmlFor="" className="label-inputs">
                    Select business type
                  </label>
                  <div className="radio-container-cardetails">
                    {businessTypeList.map((data, key) => (
                      <RadioButton
                        key={key}
                        label={data.label}
                        groupName={data.groupName}
                        value={data.value}
                        id={data.id}
                        changeEvent={data.changeEvent}
                        isChecked={
                          FormData?.businessType === data.value ? true : false
                        }
                      />
                    ))}
                  </div>
                  {errors?.businessType && (
                    <p className="err-msg">{errors?.businessType}</p>
                  )}
                </div>

                <div
                  className={
                    FormData?.businessType === "New"
                      ? "inp-container-vehicledetails disabled-inputField"
                      : "inp-container-vehicledetails"
                  }
                >
                  <label htmlFor="" className="label-inputs">
                    Please enter your vehicle number
                  </label>
                  <InputFields
                    type="text"
                    placeholder="My registration number (MH 01 AB 1234)"
                    name="vehicleNumber"
                    className="vehicleNumber"
                    onChangeEvent={HanldeFormData}
                    value={FormData.vehicleNumber}
                    errState={errors?.vehicleNumber ? true : false}
                    errMsg={errors?.vehicleNumber}
                    errClassName="input-err"
                  />
                </div>

                <div className="btngrp-container">
                  <Button
                    text="Reset"
                    className="secondry_btn btn-vehicledetails "
                    event={(e) => HanldeFormData("", "resetData")}
                  />
                  <Button
                    text="Continue"
                    className="button btn-vehicledetails "
                    event={(e) => setComponentToRender("cardetails")}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CarDetails;
