import { createAsyncThunk } from "@reduxjs/toolkit";

export const getBikeBrandAction = createAsyncThunk("getBrand", async () => {
    const response = await fetch(
        `https://5hai4w5wmdpyflh5qc45kxpbme0qztck.lambda-url.us-east-1.on.aws?type=brand&vehicleType=bike`,
        {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
        }
    );
    return response.json();
});

export const getBikeModelAction = createAsyncThunk("getModel", async (id) => {
    const response = await fetch(
        `https://5hai4w5wmdpyflh5qc45kxpbme0qztck.lambda-url.us-east-1.on.aws?Id=${id}&type=model&vehicleType=bike`,
        {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
        }
    );
    return response.json();
});

export const getVariantAction = createAsyncThunk(
    "getVariant",
    async (reqObj) => {
        const response = await fetch(
            `${process.env.REACT_APP_GET_MAKE_MODEL_URL}?Id=${reqObj?.id}&Model=${reqObj?.modelName}&fuelType=${reqObj?.selectedFuel}&type=variant&vehicleType=bike`,
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }
        );
        return response.json();
    }
);