import React, { useState, useEffect } from "react";
import "../InputField.css";

const InputFields = (props) => {
  const {
    value,
    placeholder,
    id,
    type,
    onChangeEvent,
    className,
    name,
    isDisabled,
    errClassName,
    errState,
    errMsg,
  } = props;

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (value === "" || value === null || value === undefined) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
    return () => {};
  }, [value]);

  return (
    <>
      <div>
        <div id="float-label">
          <input
            type={type}
            name={name}
            id={id}
            value={value ? value : ""}
            className={errState ? errClassName + " " + className : className}
            onChange={(e) => onChangeEvent(e)}
            disabled={isDisabled | false}
            // autoComplete="off"
          />
          <label className={isActive ? "Active" : ""} htmlFor={id}>
            {placeholder}
          </label>
        </div>
      {errState ? <p className="err-msg">{errMsg} </p> : ""}
      </div>
    </>
  );
};

export default InputFields;
