import React, { useEffect } from "react";
import Breadcrumbs from '../../Layouts/Breadcrumbs/Breadcrumbs';
import { useSelector, useDispatch } from "react-redux";
import { getVehicleTypeList } from '../../../Redux/Actions/getVehicleTypeAction';

const VehicleType = (props) => {

    const { event, formData, handleBackBtn } = props;

    const dispatch = useDispatch();

    const getUserToken = useSelector((state) => {
        return state?.getLoggedInStatus?.data?.loginData?.token;
    });

    useEffect(() => {
        dispatch(getVehicleTypeList(getUserToken));
    }, []);

    const VehicleTypeList = useSelector((state) => {
        return state?.getVehicleType?.data?.data;
    })

    return (
        <>
            <section className="productselection-container">
                <div className="productselection-parent vehicleType-parent maxWidth">
                    <div className="breadCrumb-parent ">
                        <Breadcrumbs event={handleBackBtn} componentName="SelectVehicleType" />
                    </div>
                    <div className="productContainer">
                        {VehicleTypeList?.map((data, key) => (
                            <div
                                key={key}
                                className="prod-block"
                                onClick={(e) => event(data, "vehicleType")}
                            >
                                <div className="icon-container-prod">
                                    <img
                                        src={data.vehicle_img}
                                        className="prod-block-img"
                                    />
                                </div>
                                <p className="prod-name">{data.type}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default VehicleType
