import { createAsyncThunk } from "@reduxjs/toolkit";

export const loginAction = createAsyncThunk(
  "loginData",
  async (bodyData) => {
    const response = await fetch(
      //"https://zrmmh5h8wj.execute-api.us-east-1.amazonaws.com/default/bmi-pos-login", //UAT
      "https://gkaxpf3fw4.execute-api.ap-south-1.amazonaws.com/prod/prod_brilljust_send_verify_otp", //PROD
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);