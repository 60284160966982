import "./App.css";
import InvoicePdf from "./Components/PDF-Container/InvoicePdf";
import PolicyPdf from "./Components/PDF-Container/PolicyPdf";
import Root from "./Root";

function App() {
  return (
    <>
      <Root />
      {/* <PolicyPdf /> */}
      {/* <InvoicePdf /> */}
    </>
  );
}

export default App;
