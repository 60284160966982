import React, { useState } from "react";
import "./DownloadPolicy.css";
import success from "../../../Assets/Images/paymentSucces.svg";
// import loader2 from "../../Assets/Loader/Loader.gif";
import Button from "../../InputField/Button/Button";
import { exportPDF } from "../../../utils/commonUtils";
import Loader from "../../Layouts/Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserDetailsAction } from "../../../Redux/Actions/getUserDetails";

const DownloadPolicy = (props) => {
  const { FormData, policyNumber } = props;
  const [loading, setLoading] = useState(false);

  const getUserToken = useSelector((state) => {
    return state?.getLoggedInStatus?.data?.loginData?.token;
  });

  const dispatch = useDispatch();

  let formDataBody = { ...FormData };

  const setUserDetails = async () => {
    let userDetails = await dispatch(fetchUserDetailsAction(getUserToken));
    if (userDetails.payload.status === 1) {
      if (
        formDataBody.isCompleteCareChecked === true &&
        formDataBody.cc !== null &&
        formDataBody.vehicleType === "Two Wheeler"
      ) {
        if (formDataBody.cc > 150) {
          formDataBody = {
            ...formDataBody,
            ...{
              is_opt_gst: userDetails?.payload?.data?.is_opt_gst,
              cc_plan_amount: userDetails?.payload?.cc_plan_details?.two_wheeler_more_than_150,
              is_hide_master_policy: userDetails?.payload?.data?.is_hide_master_policy,
            },
          };
        } else if (formDataBody.cc <= 150) {
          formDataBody = {
            ...formDataBody,
            ...{
              is_opt_gst: userDetails?.payload?.data?.is_opt_gst,
              cc_plan_amount: userDetails?.payload?.cc_plan_details?.two_wheeler_less_than_150,
              is_hide_master_policy: userDetails?.payload?.data?.is_hide_master_policy,
            },
          };
        } else {
          formDataBody = {
            ...formDataBody,
            ...{
              is_opt_gst: userDetails?.payload?.data?.is_opt_gst,
              cc_plan_amount: 0,
              is_hide_master_policy: userDetails?.payload?.data?.is_hide_master_policy,
            },
          };
        }
      } else {
        formDataBody = {
          ...formDataBody,
          ...{
            is_opt_gst: userDetails?.payload?.data?.is_opt_gst,
            cc_plan_amount: 0,
          },
        };
      }
    } else {
      formDataBody = { ...formDataBody, ...{ is_opt_gst: 0 } };
    }
  };

  return (
    <>
      {/* LOADER CODE-----> */}
      {loading ? <Loader /> : null}

      <div className="download-page-container">
        <section className="redirectContainer">
          <div className="content-block-redirect">
            <img src={success} alt="" srcSet="" />
            <p className="msg-edirect-succes">Thank You!</p>
            <p className="msg-edirect">
              Your payment was successful. <br /> Policy Number is{" "}
              <b>{policyNumber}</b>. <br /> You can now download your policy.
            </p>
            <Button
              text="Download Policy"
              event={async () => {
                setLoading(true);
                await setUserDetails();
                await exportPDF(formDataBody, policyNumber);
                setLoading(false);
              }}
              className="button btn-vehicledetails"
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default DownloadPolicy;
