import React from "react";
import "./Button.css";

const Button = (props) => {
  const { text, className, classNameImg, style, name, event, icons, spanTxt } =
    props;

  return (
    <button className={className} name={name} style={style} onClick={event}>
      <div className={classNameImg}>
        <img src={icons} alt="" srcSet="" />
      </div>
      <span> {spanTxt} </span> {text}
    </button>
  );
};

export default Button;
