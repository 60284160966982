import React from "react";
import "./CheckBox.css";

const CheckBox = (props) => {
  const {
    label,
    className,
    classNameTwo,
    checked,
    value,
    changeEvent,
    name,
    id,
    idForDropdown,
    idForP,
    title,
  } = props;

  return (
    <div>
      <label
        id={idForDropdown}
        class={classNameTwo ? classNameTwo : "container"}
      >
        <input
          type="checkbox"
          checked={checked}
          value={value}
          onChange={(e) => changeEvent(e)}
          name={name}
          id={id}
          title={title}
        />
        <div className="checkmark"></div>
        <p id={idForP} className={className} style={{ cursor: "pointer" }} >
          {label}
        </p>
      </label>
    </div>
  );
};

export default CheckBox;
