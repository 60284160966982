import React from "react";
import "./ProfileCard.css";
import pic from "../../../../Assets/Images/picture.svg";
import deelete from "../../../../Assets/Images/delete.svg";
import InputFields from "../../../InputField/TextField/InputField";
import Button from "../../../InputField/Button/Button";

const ProfileCard = (props) => {
  const { FormValue, handleInputChange } = props;

  return (
    <>
      <div className="ProfileCard-container">
        <div className="input_group_pform">
          <div className="inputGropOne_form inputGrop ">
            <InputFields
              value={FormValue.fullName}
              placeholder="Full Name"
              id="fullName"
              type="text"
              name="fullName"
              onChangeEvent={(e) => handleInputChange(e)}
            />
          </div>
          <div className="inputGropOne_form inputGrop ">
            <InputFields
              value={FormValue.phoneNumber}
              placeholder="Phone Number"
              id="phoneNumber"
              type="number"
              name="phoneNumber"
              onChangeEvent={(e) => handleInputChange(e)}
            />
          </div>
        </div>

        <div className="input_group_pform">
          <div className="inputGropOne_form inputGrop ">
            <InputFields
              value={FormValue.email}
              placeholder="Email ID"
              id="email"
              type="text"
              name="email"
              onChangeEvent={(e) => handleInputChange(e)}
            />
          </div>
          <div className="inputGropOne_form inputGrop "></div>
        </div>

        <div className="heading_rightSide_findMycar">
          <p>My car Documents</p>
          <p className="info_block_left_desc">
            We make sure your documents are confidential with us. <br />
            You can upload your Car RC, Driving Licence, PAN Card Aadhar Card,
            etc
          </p>
        </div>

        <div className="input_group_pform">
          <div className="inputGropOne_form inputGrop inputGrop-profile ">
            <div className="inputGropOne_form inputGrop inputGropKyc-custom ">
              <p>Upload Document</p>
              <input
                type="file"
                className="hideRealUploadBtn"
                id="uploadImg-Aadhar"
                accept="image/*, application/pdf"
                name="userUploadedDocument"
                onChange={(e) => handleInputChange(e)}
                hidden
              />
              <label className="customLabel-kyc" htmlFor="uploadImg-Aadhar">
                <p className="btn-kyc-">upload</p>
              </label>
            </div>
            <p>File cannot be more then 1MB. (JPG or PNG)</p>
          </div>
          <div className="inputGropOne_form inputGrop "></div>
        </div>

        <div className="upload-msg-profile">
          <div className="pic-square">
            <img src={pic} alt="" srcSet="" />
          </div>
          <div className="delete-container">
            <img src={deelete} alt="" srcSet="" />
            <p>RC Copy</p>
          </div>
        </div>

        <section className="btnContainer_pform">
          <Button text="Reset" className="button btn_pForm  secondry_btn" />
          <Button text="Update" className="button btn_pForm  " />
        </section>
      </div>
    </>
  );
};

export default ProfileCard;
