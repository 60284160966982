import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchClientDetails = createAsyncThunk(
    "fetchClientDetails",
    async (data) => {
        const response = await fetch(
            `https://h2v0zqu6h5.execute-api.ap-south-1.amazonaws.com/prod/prod_bmi_getPolicyDetails`,
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: new Headers({
                    'Content-Type': 'application/json'
                }),
            }
        );
        return response.json();
    }
);

export const fectchEngineValidaionAction = createAsyncThunk(
    "fectchEngineValidaion",
    async (data) => {
        const response = await fetch(
            `https://gkaxpf3fw4.execute-api.ap-south-1.amazonaws.com/prod/prod_brilljust_validate_vehicle`,
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: new Headers({
                    'Content-Type': 'application/json'
                }),
            }
        );
        return response.json();
    }
);