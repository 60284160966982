import React, { useEffect } from "react";
import "./Navbar.css";
import Logo from "../../../Assets/Images/brillJustLogo.svg";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getWalletBalance } from "../../../Redux/Actions/fetchWalletBalance";
import wallet from "../../../Assets/Images/wallet.svg";
import reload from "../../../Assets/Images/reload.svg";

const Navbar = (props) => {
  const { component, setComponentToRender, HanldeFormData } = props;

  // navigation
  const navigate = useNavigate();

  // navigation
  const dispatch = useDispatch();

  // loggedInStatus
  const isLoggedIn = useSelector((state) => {
    return state?.getLoggedInStatus?.data?.isLoggedIn;
  });

  // userToken
  const userToken = useSelector((state) => {
    return state?.getLoggedInStatus?.data?.loginData?.token;
  });


  // getWalletData
  useEffect(() => {
    dispatch(getWalletBalance(userToken));
  }, []);

  const fetchWalletBalance = () => {
    dispatch(getWalletBalance(userToken));
  }

  const walletBalance = useSelector((state) => {
    return state?.getWalletData?.data?.data?.balance;
  });


  const navigateHome = () => {
    if (component) {
      navigate("/View-Plans");
      setComponentToRender("navbar");
      HanldeFormData("", "resetData");
    } else {
      if (isLoggedIn) {
        navigate("/View-Plans");
      } else {
        navigate("/");
      }
    }
  };

  return (
    <>
      <nav className="nav-container">
        <div className="nav-parent maxWidth ">
          <img
            src={Logo}
            className="bmi-logo"
            alt="bmi-logo"
            srcSet=""
            onClick={navigateHome}
          />

          {userToken && (
            <div className="btn-container-navbar">
              <button className="wallet-btn" onClick={fetchWalletBalance}>
                <div className="wallet-div">
                  <img src={wallet} alt="" srcSet="" />
                </div>
                ₹ {walletBalance}
                <img src={reload} alt="" srcSet="" />
              </button>
              {component}
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
