import React from "react";
import loader from "../../../Assets/GIF/Loader.gif";
import './Loader.css'

const Loader = () => {
  return (
    <div id="cover-spin">
      <img src={loader} className="gifLoader" />
    </div>
  );
};

export default Loader;
